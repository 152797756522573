import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { missingAccountsToMessage } from 'bookkeeping/missing-accounts';
import { strategyList, BookkeepingCustomer, BookkeepingCustomerCombinedInvoices, ClientBilling, ClientTransferTarget, ClientTransferTargetMessage, IntegrationStrategy, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';
import { nextItem, previousItem, state } from './state';
import { BookkeepingUtil } from 'utils/bookkeeping-util';

@autoinject
export class BillingHandle {
  private customerIdAndName?: string;
  private customerList: BookkeepingCustomer[] = [];
  private selectedCustomer?: BookkeepingCustomer;
  private cb: ClientBilling = {
    id: 0,
    clientId: 0,
    businessDate: new Date(),
    customerName: "",
    businessId: "",
    locationName: "",
    customerBusinessId: "",
    accountingId: 0,
    billId: 0,
    paymentTotal: 0,
    paymentType: 'cash',
  };
  private pdf = "";
  private reject = false;
  private send?: string;

  private manualEntryTarget?: ClientTransferTarget;
  private manualEntry = false;
  private manualEntryName = "";
  private manualEntryBusinessId = "";
  private manualEntryCombinedInvoices = false;
  private status: "OPEN" | "COMPLETED" | "FINISHED" | "ERROR" | "HIDDEN" = "OPEN";
  private statusTr = "";
  private completedActor?: string;
  private deleteActor?: string;
  private mappedIntegrationErrorMessageFi?: string;
  private mappedIntegrationErrorMessageEn?: string;
  private cttm?: ClientTransferTargetMessage;
  private showMenu = false;
  private unitName?: string;
  private combinedInvoicesCustomer = false;

  private state = state;
  private previousItem?: number;
  private nextItem?: number;
  private hasQueue = state.back == "billing/incomplete-list";
  private sentOrStillSending = false;

  private showBusinessDateEditModal = false;

  private missingAccounts = "";
  private targetId?: number;
  private strategy?: IntegrationStrategy= undefined;

  constructor(private readonly router: Router, private readonly api: MyHttpApi, private readonly notify: Notify, private readonly i18n: I18N) {
  }
  // * Refresh the whole page. Aurelia triest to reuse the current view, and breaks $route1 --> $route1 navigation. 
  determineActivationStrategy() {
    return "replace";
  }

  async activate(params: { id?: string; }) {
    let paramId = parseInt(params.id || "0");
    this.nextItem = nextItem(paramId, this.state.incompleteInvoiceList);
    this.previousItem = previousItem(paramId, this.state.incompleteInvoiceList);
    await this.mainrun(paramId);
  }

  async mainrun(paramId: number) {
    let [cbAndMeta, imList] = await Promise.all([
      this.api.clientSaleBillingBillingAndMetaById({ id: paramId }),
      this.api.integrationMessageList({
        clientBillingId: paramId,
        level: 'ERROR',
        reason: 'MISSING_ACCOUNTS',
        showAlsoHidden: true,
      }),
    ]);
    if (imList.length) {
      const im = imList[0];
      this.missingAccounts = missingAccountsToMessage(this.i18n, im.missingAccounts);
      this.targetId = im.targetId;
    }
    this.strategy = cbAndMeta.strategy;
    this.cb = cbAndMeta.cb;
    this.pdf = cbAndMeta.pdf;
    let client = await this.api.clientById({ id: this.cb.clientId });
    this.unitName = client.nickname;

    this.sentOrStillSending = cbAndMeta.sentOrStillSending;
    this.completedActor = cbAndMeta.completeActor;
    this.deleteActor = cbAndMeta.deleteActor;

    this.cttm = cbAndMeta.cttm;
    this.calculateStatus(this.cb, this.cttm);
    this.calculateClientBillingRelated();
    this.manualEntryName = this.cb.billingName || '';
    this.manualEntryBusinessId = this.cb.businessId || '';

    this.mappedIntegrationErrorMessageFi = cbAndMeta.mappedIntegrationErrorMessage?.textFi || this.cttm?.status;
    this.mappedIntegrationErrorMessageEn = cbAndMeta.mappedIntegrationErrorMessage?.textEn || this.cttm?.status;

    if (this.cb.paymentType === "billing") {
      this.customerList = await this.api.bookkeepingCustomerList({ clientBillingId: this.cb.id });
    } else if (this.cb.paymentType === "combined_billing") {
      this.customerList = await this.api.bookkeepingCustomerListByClientWithCombinedBilling({ clientId: client.id });
    }
    let id = this.cb.bookkeepingId + " ";
    this.customerIdAndName = this.customerListNames.find(p => p.startsWith(id));
    if (!this.sentOrStillSending && !this.manualEntry && this.customerIdAndName) {
      await this.setCustomer(this.customerIdAndName);
    }
    // Do we have combinedInvoice enabled customer
    let customer = this.customerList.find(p => p.bookkeepingId == this.cb?.bookkeepingId);
    if (customer) {
      let bcci = await this.api.bookkeepingCustomerCombinedInvoicesByCustomerAndClient({ customerId: customer.id, clientId: this.cb.clientId });
      this.combinedInvoicesCustomer = bcci?.combinedInvoices || false;
    }
    this.manualEntryTarget = await this.api.clientTransferTargetManualByClientId({ clientId: this.cb.clientId });
  }

  @computedFrom("sentOrStillSending", "cb.deleteTime")
  get readOnly() {
    return (this.sentOrStillSending || !!this.cb?.deleteTime);
  }

  @computedFrom("readOnly", "cb.paymentType")
  get canSaveAndSend() {
    return (!this.missingAccounts && !this.readOnly && this.cb?.paymentType === "billing");
  }

  @computedFrom("readOnly", "cb.paymentType", "combinedInvoicesCustomer")
  get canTransferToCombinedBilling() {
    return (!this.missingAccounts && !this.readOnly && this.cb?.paymentType === "billing" && this.combinedInvoicesCustomer);
  }

  @computedFrom("readOnly", "cb.paymentType")
  get canTransferToSalesInvoice() {
    return (!this.missingAccounts && !this.readOnly && this.cb?.paymentType === "combined_billing");
  }

  calculateStatus(cb: ClientBilling, cttm?: ClientTransferTargetMessage) {
    this.status = "OPEN";
    if (cttm?.finishTime) {
      this.status = "FINISHED";
    } else if (cttm && cttm.attemptCount > 0 && !cttm.finishTime) {
      this.status = "ERROR";
    } else if (cb.deleteTime) {
      this.status = "HIDDEN";
    } else if (cb.completedTime) {
      this.status = "COMPLETED";
    }
  }

  textToHtmlLineBreaks(text: string) {
	return text.replace("\n","<br>");
  }

  calculateClientBillingRelated() {
    if (!this.cb.billingCountry || this.cb.billingCountry == "Finland") {
      this.cb.billingCountry = "FIN";
    }
    if (this.cb.invoiceOperator === "SMTP") {
      this.send = "email";
    } else if (this.cb.invoiceOperator) {
      this.send = "einvoice";
    } else {
      this.send = "paper";
    }
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  @computedFrom("customerList")
  get customerListNames() {
    return this.customerList.map(c => c.bookkeepingId + " " + BookkeepingUtil.generateCustomerDropdownName(c));
  }

  useManualEntry() {
    this.manualEntry = !this.manualEntry;
    this.manualEntryCombinedInvoices = this.cb ? this.cb.paymentType == "combined_billing" : false;
  }

  @computedFrom("pdf")
  get pdfUrl() {
    if (!this.pdf) {
      return "";
    }
    return "data:application/pdf;base64," + this.pdf;
  }

  async setCustomer(customerIdStr: string) {
    if (!this.cb) {
      return;
    }

    let id = parseInt(customerIdStr.split(" ")[0]);
    this.selectedCustomer = this.customerList.find(c => c.bookkeepingId === id);
    if (this.selectedCustomer?.id) {
      let bcci = await this.api.bookkeepingCustomerCombinedInvoicesByCustomerAndClient({ customerId: this.selectedCustomer.id, clientId: this.cb.clientId });
      this.combinedInvoicesCustomer = bcci?.combinedInvoices || false;
    }

    this.cb.bookkeepingId = this.selectedCustomer?.bookkeepingId;
    this.cb.businessId = this.selectedCustomer?.businessId;
    this.cb.billingName = this.selectedCustomer?.name;
    this.cb.billingName2 = this.selectedCustomer?.additionalName;
    this.cb.billingOfficialName = this.selectedCustomer?.officialName;
    this.cb.billingAddress = this.selectedCustomer?.streetAddress;
    this.cb.billingZip = this.selectedCustomer?.zip;
    this.cb.billingCity = this.selectedCustomer?.city;
    this.cb.billingCountry = this.selectedCustomer?.country;
    this.cb.invoiceAddress = this.selectedCustomer?.invoiceAddress;
    this.cb.invoiceOperator = this.selectedCustomer?.invoiceOperator;
    this.calculateClientBillingRelated();
  }

  async maybeUpdateCustomer() {
    if (this.manualEntryTarget && this.manualEntry) {
      let bi = this.cb;
      let customer = await this.api.bookkeepingCustomerUpdate({
        delete: false,
        targetId: this.manualEntryTarget.id,
        businessId: this.manualEntryBusinessId,
        officialName: this.manualEntryName || '',
        name: bi.billingName || "",
        additionalName: bi.billingName2,
        streetAddress: bi.billingAddress || "",
        zip: bi.billingZip || "",
        city: bi.billingCity || "",
        country: bi.billingCountry || "",
        invoiceAddress: bi.invoiceAddress || undefined,
        invoiceOperator: bi.invoiceOperator || undefined,
        agreementIdentifier: this.selectedCustomer?.agreementIdentifier,
        combinedInvoicesList: [<BookkeepingCustomerCombinedInvoices>{ clientId: this.cb.clientId, combinedInvoices: this.manualEntryCombinedInvoices }]
      });
      bi.bookkeepingId = customer.bookkeepingId;
    }
  }

  /** 
   * If we came from anywhere else than incomplete-list, always move back to listing.
   * Otherwise, we check if we have next in line and move there
   */
  nextOrBack() {
    if (!this.hasQueue || !this.nextItem) {
      this.back();
      return;
    }
    this.moveTo(this.nextItem);
  }

  hasNext() {
    return (this.hasQueue && this.nextItem);
  }

  hasPrev() {
    return (this.hasQueue && this.previousItem);
  }

  back() {
    this.router.navigateToRoute(this.state.back);
  }

  /** Reload current viewmodel with new param */
  moveTo(where?: number) {
    this.router.navigateToRoute(this.router.currentInstruction.config.name!, { id: "" + where });
  }
  async formSubmit(event: SubmitEvent) {
    this.showMenu = false;
    if (this.send == "email") {
      this.cb.invoiceOperator = 'SMTP';
    } else if (this.send == "paper") {
      this.cb.invoiceAddress = undefined;
      this.cb.invoiceOperator = undefined;
    }

    let id = event.submitter?.id || "";
    if (["transferToSalesInvoice", "transferToCombinedBilling", "send", "save"].includes(id)) {
      await this.maybeUpdateCustomer();
    }
    if (id === "transferToCombinedBilling") {
      await this.transferToCombinedBilling();
    } else if (id === "send") {
      await this.completeSalesInvoice();
    } else if (id === "save") {
      await this.saveCombinedBilling();
    }
  }

  async toggleHide() {
    this.showMenu = false;
    await this.maybeUpdateCustomer();
    await this.api.clientSaleBillingToggleHide({ ...this.cb });
    if (this.cb?.deleteTime) {
      // Restore - we want to stay on the same page
      await this.mainrun(this.cb.id);
    } else {
      this.nextOrBack();
    }
  }

  async saveCombinedBilling() {
    await this.api.combinedBillingSave({ ...this.cb });
    this.nextOrBack();
  }

  async completeSalesInvoice() {
    await this.maybeUpdateCustomer();
    await this.api.salesInvoiceComplete({ ...this.cb });
    this.nextOrBack();
  }

  async transferToCombinedBilling() {
    await this.api.salesInvoiceTransferToCombinedBilling({ ...this.cb });
    this.nextOrBack();
  }

  // * Will be sent to unhandled, so no need to validate
  async transferToSalesInvoice() {
    this.showMenu = false;
    await this.api.combinedBillingTransferToSalesInvoice({ ...this.cb });
    // Most likely want to stay in same page
    await this.mainrun(this.cb.id);
  }

  async changeBusinessDate() {
    this.showBusinessDateEditModal = false;
    await this.api.clientSaleBillingUpdateBusinessDate({
      id: this.cb.id,
      businessDate: this.cb.businessDate,
    });
    this.notify.info("client-billing.businessDateChanged");
  }

  async navigateToCustomer() {
    if (!this.selectedCustomer) {
      return;
    }
    let id = this.selectedCustomer.id;
    let targetId = this.selectedCustomer.targetId;
    await this.api.salesInvoiceUpdateBillInfo({ ...this.cb });
    this.state.backCustomer = this.router.currentInstruction?.config.name || '/';
    this.state.backCustomerParams = this.router.currentInstruction?.queryParams || {};
    this.router.navigateToRoute("billing/customers/edit", { id: "" + id, targetId: targetId });
  }

  @computedFrom("i18n.i18next.language")
  get lang() {
    return this.i18n.i18next.language;
  }

  @computedFrom("strategy")
  get internalInfoSplash() {
    if (!this.strategy) {
      return "";
    }
    const data = strategyList.find(x => x.id == this.strategy)!;
    const trKey = data.supportInternalNote ? "internalInfoWillBeRelayed" : "internalInfoWillNotBeRelayed";
    return this.i18n.tr("client-billing." + trKey, {system: data.name});
  }
}
